<template>
  <section
    id="partnership"
    class="section bg-light-grad"
  >
    <div class="container">
      <div class="section-head text-center text-lg-left">
        <h6 class="title title-xs title-s1 tc-primary">
          {{ $t('partnershipSection.partnership') }}
        </h6>
        <h2 class="title">
          {{ $t('partnershipSection.title') }}
        </h2>
      </div>
      <!-- Block @s -->
      <div class="nk-block block-partners">
        <ul class="partner-list partner-list-center partner-list-s4 flex-wrap">
          <li class="partner-logo partner-logo-s4">
            <img
              src="@/assets/images/partners/modalmu.png"
              alt="PT. Fintek Modalmu Bersatu"
            >
          </li>
          <li class="partner-logo partner-logo-s4">
            <img
              src="@/assets/images/partners/kspbmb.png"
              alt="Koperasi Berkat Mandiri Bersatu"
            >
          </li>
        </ul>
      </div>
      <!-- Block @e -->
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
// .partner-logo {
//   img {
//     filter: grayscale(100%) opacity(70%);
//     transition: all .2s ease;
//     transition-duration: .2s;

//     &:hover {
//       filter: grayscale(0) opacity(100%);
//     }
//   }
// }
</style>
