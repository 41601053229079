<template>
  <header
    id="header"
    class="nk-header page-header is-sticky is-shrink is-transparent is-light"
  >
    <!-- Header @s -->
    <div class="header-main">
      <div class="header-container container">
        <div class="header-wrap">
          <!-- Logo @s -->
          <div
            class="header-logo logo"
            data-aos="fade-down"
            data-aos-delay="65"
          >
            <span
              :class="{'d-none': theme === 'light'}"
              class="logo-link"
              @click="goTop()"
            >
              <img
                class="logo-on-top"
                src="@/assets/images/logo/logo-bw-light.svg"
                srcset="@/assets/images/logo/logo-bw-light.svg 2x"
                alt="logo"
              >
              <img
                class="logo-on-scroll"
                src="@/assets/images/logo/logo-bw-light.svg"
                srcset="@/assets/images/logo/logo-bw-light.svg 2x"
                alt="logo"
              >
            </span>
            <span
              :class="{'d-none': theme === 'dark'}"
              class="logo-link"
              @click="goTop()"
            >
              <img
                class="logo-on-top"
                src="@/assets/images/logo/logo-bw-dark.svg"
                srcset="@/assets/images/logo/logo-bw-dark.svg 2x"
                alt="logo"
              >
              <img
                class="logo-on-scroll"
                src="@/assets/images/logo/logo-bw-dark.svg"
                srcset="@/assets/images/logo/logo-bw-dark.svg 2x"
                alt="logo"
              >
            </span>
          </div>

          <div class="mobile-theme-changer">
            <ul
              class="menu theme-changer"
              data-aos="fade-down"
              data-aos-delay="75"
            >
              <li class="menu-item theme-button">
                <div
                  :class="{'hidden': theme === 'dark'}"
                  class="btn-theme-light"
                  @click="changeTheme('dark')"
                >
                  <light-mode />
                </div>
                <div
                  :class="{'hidden': theme === 'light'}"
                  class="btn-theme-dark"
                  @click="changeTheme('light')"
                >
                  <dark-mode />
                </div>
              </li>
            </ul>
          </div>

          <!-- Menu Toogle @s -->
          <div class="header-nav-toggle">
            <span
              class="navbar-toggle"
              data-menu-toggle="header-menu"
              @click="openSidebar()"
            >
              <div class="toggle-line">
                <span />
              </div>
            </span>
          </div>

          <!-- Menu @s -->
          <div class="header-navbar header-navbar-s1">
            <nav
              id="header-menu"
              class="header-menu"
            >
              <ul
                class="menu"
                data-aos="fade-down"
                data-aos-delay="75"
              >
                <li class="menu-item">
                  <a
                    class="menu-link nav-link"
                    href="#about"
                    @click="scrollPageTo('#about', $event)"
                  >
                    {{ $t('navbar.about') }}
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    class="menu-link nav-link"
                    href="#services"
                    @click="scrollPageTo('#services', $event)"
                  >
                    {{ $t('navbar.services') }}
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    class="menu-link nav-link"
                    href="#partnership"
                    @click="scrollPageTo('#partnership', $event)"
                  >
                    {{ $t('navbar.partnership') }}
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    class="menu-link nav-link"
                    href="#contact"
                    @click="scrollPageTo('#contact', $event)"
                  >
                    {{ $t('navbar.contact') }}
                  </a>
                </li>
              </ul>
              <ul
                class="menu-btns menu-btns-s3 align-items-center"
                data-aos="fade-down"
                data-aos-delay="85"
              >
                <li class="language-switcher language-switcher-s1 toggle-wrap">
                  <span
                    class="toggle-tigger"
                    @click="openToggle('#change-language')"
                  >
                    {{ $i18n.locale }}
                  </span>
                  <language-switcher />
                </li>
              </ul>
              <ul
                class="menu theme-changer"
                data-aos="fade-down"
                data-aos-delay="75"
              >
                <li class="menu-item theme-button">
                  <div
                    :class="{'hidden': theme === 'dark'}"
                    class="btn-theme-light"
                    @click="changeTheme('dark')"
                  >
                    <light-mode />
                  </div>
                  <div
                    :class="{'hidden': theme === 'light'}"
                    class="btn-theme-dark"
                    @click="changeTheme('light')"
                  >
                    <dark-mode />
                  </div>
                </li>
              </ul>
            </nav>
            <div
              class="header-navbar-overlay"
              @click="closeSidebar()"
            />
          </div><!-- .header-navbar @e -->
        </div>
      </div>
    </div><!-- .header-main @e -->
    <!-- Banner @s -->
    <div class="header-banner bg-light ov-h header-banner-jasmine">
      <div class="background-shape" />
      <div class="nk-banner">
        <div class="banner banner-fs banner-single">
          <!-- .block @s -->
          <div class="nk-block nk-block-header my-auto">
            <div class="container">
              <div class="row align-items-center flex-row-reverse">
                <div class="col-lg-6">
                  <img
                    data-aos="fade-down"
                    data-aos-delay="125"
                    alt="Brainworks-Hero"
                    src="@/assets/images/header/header.png"
                  >
                </div><!-- .col -->
                <div class="col-lg-6">
                  <div class="banner-caption text-center text-lg-left">
                    <div class="cpn-head">
                      <h1
                        class="title title-xl-2"
                        data-aos="fade-up"
                        data-aos-delay="125"
                      >
                        {{ $t('hero.tagline1') }}
                        <span class="d-block thin">
                          {{ $t('hero.tagline2') }}
                        </span>
                      </h1>
                    </div>
                    <div class="cpn-text cpn-text-s2">
                      <p
                        class="lead-s2"
                        data-aos="fade-up"
                        data-aos-delay="135"
                      >
                        {{ $t('hero.description1') }}<br><br>
                        {{ $t('hero.description2') }}<br><br>
                        <span class="disclaimer">{{ $t('hero.disclaimer') }}</span>
                      </p>
                    </div>
                    <div class="cpn-action">
                      <a
                        href="#contact"
                        class="btn btn-s2 btn-md btn-grad"
                        data-aos="fade-up"
                        data-aos-delay="145"
                        @click="scrollPageTo('#contact', $event)"
                      >
                        {{ $t('navbar.contact').toUpperCase() }}
                      </a>
                    </div>
                  </div>
                </div><!-- .col -->
              </div><!-- .row -->
            </div>
          </div><!-- .block @e -->
          <!-- .block @s -->
          <div class="w-100 nk-block-status">
            <div class="container">
              <div class="row justify-content-end">
                <div class="token-status token-status-s4 pb-4 text-right">
                  <ul
                    class="icon-list"
                    data-aos="fade-up"
                    data-aos-delay="165"
                  >
                    <li class="facebook">
                      <em class="fab fa-facebook" />
                    </li>
                    <li class="twitter">
                      <em class="fab fa-twitter" />
                    </li>
                    <li class="instagram">
                      <em class="fab fa-instagram" />
                    </li>
                  </ul>
                </div>
              </div><!-- .col -->
            </div><!-- .row -->
          </div><!-- .block @e -->
        </div>
      </div><!-- .nk-banner -->
    </div>
    <!-- .header-banner @e -->
  </header>
</template>

<script>
import LightMode from '@/components/header/LightModeSVG.vue'
import DarkMode from '@/components/header/DarkModeSVG.vue'
import store from '@/store/index'
import { mapGetters } from 'vuex'
import LanguageSwitcher from './LanguageSwitcher.vue'

export default {
  components: {
    LightMode,
    DarkMode,
    LanguageSwitcher,
  },
  data() {
    return {
      bodyContent: null,
      toggleButton: null,
      headerNavClassic: null,
      headerNavbar: null,
      rocket: null,
    }
  },
  computed: {
    ...mapGetters({
      rocketLaunch: 'landing/getRocket',
      theme: 'landing/getTheme',
    }),
  },
  watch: {
    async rocketLaunch(newValue) {
      if (newValue) {
        const element = document.scrollingElement
        const start = element.scrollTop
        const change = 0 - start
        let duration = (Math.abs(change) * 100) / window.innerHeight
        duration = duration - (duration * 0.65) + (duration + 200)
        this.rocket.classList.add('launch')
        this.rocket.style.transition = `bottom ${duration / 1000}s`
        element.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      } else {
        const afterLaunch = document.querySelector('.rocket')
        afterLaunch.classList.remove('launch')
        setTimeout(() => {
          afterLaunch.style.transition = 'height 0.5s, opacity 0.3s'
        }, 200)
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.checkScrollPosition)
    const headerNavbar = document.querySelector('.header-navbar')
    if (window.innerWidth < 992) {
      setTimeout(() => {
        headerNavbar.classList.add('menu-mobile')
      }, 500)
    } else {
      setTimeout(() => {
        headerNavbar.classList.remove('menu-mobile')
        headerNavbar.classList.remove('menu-shown')
      }, 500)
    }
    // mount all needed element
    this.bodyContent = document.querySelector('#body')
    this.toggleButton = document.querySelector('.navbar-toggle')
    const menuAttribute = this.toggleButton.getAttribute('data-menu-toggle')
    this.headerNavClassic = document.querySelector(`#${menuAttribute}`)
    this.headerNavbar = document.querySelector('.header-navbar')
    this.rocket = document.querySelector('.rocket')
  },
  methods: {
    async checkScrollPosition(event) {
      const body = document.querySelector('.nk-wrap').getBoundingClientRect()
      const elemTop = body.top
      const headerNav = document.getElementById('header')
      if (event) {
        if (elemTop < 0) {
          if (headerNav.classList.contains('is-sticky')) {
            headerNav.classList.add('has-fixed')
          }
          if (!this.rocket.classList.contains('show')) this.rocket.classList.toggle('show')
        } else if (headerNav.classList.contains('is-sticky') && elemTop === 0) {
          headerNav.classList.remove('has-fixed')
          this.rocket.classList.remove('show')
          await store.dispatch('landing/GO_TOP', false, { root: true })
        }
      }
    },
    async scrollPageTo(to, event) {
      await store.dispatch('landing/SCROLL_PAGE', { to, event }, { root: true })
      if (this.headerNavbar.classList.contains('menu-shown')) {
        this.closeSidebar()
      }
    },
    openSidebar() {
      this.toggleButton.classList.toggle('navbar-active')
      if (document.querySelector('.header-navbar-classic')) {
        this.headerNavClassic.classList.toggle('menu-shown')
      } else {
        this.headerNavClassic.parentElement.classList.toggle('menu-shown')
      }
      this.bodyContent.classList.toggle('overlay-menu-shown')
    },
    closeSidebar() {
      this.toggleButton.classList.remove('navbar-active')
      this.headerNavbar.classList.remove('menu-shown')
      this.bodyContent.classList.remove('overlay-menu-shown')
    },
    openToggle() {
      const menuToggle = document.querySelectorAll('.toggle-class')
      menuToggle.forEach(element => {
        element.classList.remove('open-menu')
      })
      const menuList = document.querySelector('#change-language')
      menuList.classList.add('open-menu')
      setTimeout(async () => {
        await store.dispatch('landing/CHANGE_TOGGLE', true, { root: true })
      }, 700)
    },
    async changeTheme(theme) {
      await store.dispatch('landing/CHANGE_THEME', theme, { root: true })
    },
    async goTop() {
      await store.dispatch('landing/GO_TOP', true, { root: true })
    },
  },
}
</script>

<style lang="scss" scoped>

.logo-link {
  img {
    cursor: pointer;
  }
}

span {
  &.navbar-toggle, &.toggle-tigger {
    cursor: pointer;
  }

  &.disclaimer {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
  }
}
.header-navbar {
  transition: all 0.5s ease 0s;
}
.toggle-class {
  &.open-menu {
    opacity: 1;
    visibility: visible;
  }
}
.mobile-theme-changer {
  position: absolute;
  right: 60px;

  .menu {
    &.theme-changer {
      .theme-button {
        margin-left: 0;
      }
    }
  }
}
.menu {
  &.theme-changer {
    padding: 10px 0;

    .theme-button {
      margin-left: 24px;

      .btn-theme-light, .btn-theme-dark {
        padding: 6px 10px;
        border-radius: 10px;
        cursor: pointer;
        opacity: 1;
        transition: all 0.5s ease 0s;
        background: transparent;

        &.hidden {
          opacity: 0;
          visibility: hidden;
        }

        svg {
          width: 24px;
          height: auto;
        }

      }

      .btn-theme-light {
        // background-image: linear-gradient(to right, #1DA2DC 0%, #1C7EC3 100%);
        position: absolute;
      }
    }

  }
}

.banner-single {
  .nk-block {
    &.nk-block-header{
      img {
        max-height: 400px;
        width: -webkit-fill-available;
      }
    }
  }
}

.banner-caption {
  .title {
    font-weight: 600;
    font-size: 30px !important;
    line-height: 1.1;
    margin: 5rem 0 2rem 0;

    span {
      font-size: 22px !important;
      font-weight: 400;
    }
  }
  .cpn-text-s2 {
    p {
      &.lead-s2 {
        line-height: 1.5;
        font-size: 15px !important;
      }
    }
  }
}

@media (min-width: 768px) {
  .banner-caption {
    .title {
      font-size: 64.5px !important;

      span {
        font-size: 40px !important;
      }
    }
    .cpn-text-s2 {
      p {
        &.lead-s2 {
          line-height: 1.5;
          font-size: 15px !important;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .mobile-theme-changer {
    .theme-changer {
      display: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .header-menu {
    .menu {
      &.theme-changer {
        display: none;
      }
    }
  }
}
</style>
