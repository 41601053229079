<template>
  <div
    class="nk-wrap"
    @click="closeToggle()"
  >
    <header-default />

    <main class="nk-pages">
      <about />
      <services />
      <partnership />
      <contact />
    </main>
    <footer-default />
    <div
      :class="{'rocket-light': theme === 'light'}"
      class="rocket"
      @click="goTop()"
    />
  </div>
</template>

<script>
import HeaderDefault from '@/components/header/HeaderDefault.vue'
import About from '@/components/home/About.vue'
import Services from '@/components/home/Services.vue'
import Partnership from '@/components/home/Partnership.vue'
import Contact from '@/components/home/Contact.vue'
import FooterDefault from '@/components/footer/FooterDefault.vue'
import store from '@/store/index'
import { mapGetters } from 'vuex'

export default {
  components: {
    HeaderDefault,
    About,
    Services,
    Partnership,
    Contact,
    FooterDefault,
  },
  data() {
    return {
      db: {},
    }
  },
  computed: {
    ...mapGetters({
      toggle: 'landing/getToggle',
      theme: 'landing/getTheme',
    }),
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async closeToggle() {
      if (this.toggle) {
        const toggleMenu = document.querySelectorAll('.toggle-class')
        toggleMenu.forEach(element => {
          element.classList.remove('open-menu')
        })
        await store.dispatch('landing/CHANGE_TOGGLE', false, { root: true })
      }
    },
    async goTop() {
      await store.dispatch('landing/GO_TOP', true, { root: true })
    },
  },
}
</script>

<style lang="scss" scope>
.drop-list {
  span {
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    color: #223b55;
    padding: 7px 20px;
    display: block;
    position: relative;
    transition: all 0.5s;

    &:hover {
      color: #16a1ff;
    }
  }
}

.section-m {
  padding-bottom: 1rem !important;
  padding-top: 3rem !important;
}

.team-person {
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.backtop {
  position: fixed;
  bottom: 10px;
  right: 0;
  z-index: 99999;
  height: 38px;
  width: 38px;
  font-size: 16px;
  line-height: 38px;
  text-align: center;
  color: rgb(255, 0, 0);
  // background: #2b56f5;
  box-shadow: 0 0 0 2px rgb(255, 0, 0);
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
}

.rocket {
  cursor: pointer;
  background-image: url('../assets/images/rocket.png');
  background-size: cover;
  background-position: top;
  bottom: 24px;
  position: fixed;
  z-index: 5;
  right: 18px;
  transition: height 0.5s, opacity 0.3s;
  height: 42px;
  width: 30px;
  opacity: 0;
  visibility: hidden;

  &.rocket-light {
    background-image: url('../assets/images/rocket-light.png');
  }

  &:hover {
    height: 54px;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }

  &.launch {
    height: 54px;
    bottom: calc(100vh - 100px)
  }
}

@media (min-width: 992px) {
  .team-person {
    margin: 0 50px;
  }
}
</style>
