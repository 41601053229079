<template>
  <section
    id="contact"
    class="section section-contact bg-white ov-h"
  >
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block block-contact">
        <div class="row justify-content-center gutter-vr-30px">
          <div class="col-lg-4">
            <div class="section-head section-head-sm section-head-s9 text-center text-lg-left">
              <h6
                class="title title-xs title-s1 tc-primary d-none"
                data-aos="fade-up"
                data-aos-delay="10"
              >
                {{ $t('contactSection.contact') }}
              </h6>
              <h2
                class="title"
                data-aos="fade-up"
                data-aos-delay="20"
              >
                {{ $t('contactSection.title') }}
              </h2>
              <div class="class">
                <p
                  data-aos="fade-up"
                  data-aos-delay="30"
                >
                  {{ $t('contactSection.description') }}
                </p>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-between h-100">
              <ul class="contact-list contact-list-s2">
                <li
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <em class="contact-icon contact-icon-s2 fas fa-envelope" />
                  <div class="contact-text">
                    <span>admin@brainworks.id</span>
                  </div>
                </li>
              </ul>
            </div>
          </div><!-- .col -->
          <div class="col-lg-4">
            <div class="gap-6x d-none d-lg-block" />
            <div class="gap-4x d-none d-lg-block" />
            <form
              id="contact-form-01"
              class="contact-form nk-form-submit"
              @submit.prevent="sendMail()"
            >
              <div
                class="field-item field-item-s2"
                data-aos="fade-up"
                data-aos-delay="70"
              >
                <input
                  id="name-input"
                  v-model="name"
                  name="contact-name"
                  type="text"
                  class="input-bordered required"
                  :placeholder="$t('contactSection.name')"
                >
              </div>
              <div
                class="field-item field-item-s2"
                data-aos="fade-up"
                data-aos-delay="80"
              >
                <input
                  id="email-input"
                  v-model="email"
                  name="contact-email"
                  type="email"
                  class="input-bordered required email"
                  :placeholder="$t('contactSection.email')"
                >
              </div>
              <div
                class="field-item field-item-s2"
                data-aos="fade-up"
                data-aos-delay="90"
              >
                <textarea
                  id="message-input"
                  v-model="message"
                  name="contact-message"
                  class="input-bordered input-textarea required"
                  :placeholder="$t('contactSection.message')"
                />
              </div>
              <div class="row">
                <div
                  class="col-sm-12"
                  data-aos="fade-up"
                  data-aos-delay="10"
                >
                  <button
                    id="btn-submit"
                    type="submit"
                    class="btn btn-s2 btn-md btn-grad"
                  >
                    {{ $t('contactSection.submit') }}
                  </button>
                </div>
                <div class="col-sm-12">
                  <div class="form-results" />
                </div>
              </div>
            </form>
          </div><!-- .col -->
          <div class="col-lg-4 align-self-center d-none d-lg-block">
            <div
              class="nk-block-img"
              data-aos="fade-up"
              data-aos-delay="110"
            >
              <img
                src="@/assets/images/reach-us.png"
                alt="reach-us"
              >
            </div>
          </div><!-- .col -->
        </div><!-- .row -->
      </div><!-- .block @e -->
    </div>
  </section>
</template>

<script>

export default {
  data() {
    return {
      name: null,
      email: null,
      message: null,
    }
  },
  methods: {
    async sendMail() {
      document.getElementById('name-input').disabled = true
      document.getElementById('email-input').disabled = true
      document.getElementById('message-input').disabled = true
      document.getElementById('btn-submit').disabled = true

      await fetch('/form/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          name: this.name,
          email: this.email,
          message: this.message,
        }),
      })
        .then(response => response.json())
        .then(responseData => {
          if (responseData.type === 'success') {
            this.name = null
            this.email = null
            this.message = null
            this.$toast.info(responseData.message)
          } else {
            this.$toast.error(responseData.message)
          }

          document.getElementById('name-input').disabled = false
          document.getElementById('email-input').disabled = false
          document.getElementById('message-input').disabled = false
          document.getElementById('btn-submit').disabled = false
        })
        .catch(error => {
          this.$toast.error(error.message)

          document.getElementById('name-input').disabled = false
          document.getElementById('email-input').disabled = false
          document.getElementById('message-input').disabled = false
          document.getElementById('btn-submit').disabled = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  &.btn {
    &.btn-grad {
      &:disabled, &[disabled] {
        cursor: progress;
        background-image: linear-gradient(to right, #a7c1cd 0%, #608fb1 100%);
      }
    }
  }
}
</style>
