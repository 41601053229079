<template>
  <section
    id="about"
    class="section bg-light-grad"
  >
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block nk-block-features-s2">
        <div class="row align-items-center gutter-vr-30px">
          <div class="col-lg-6">
            <div
              class="mx-auto mx-lg-0 p-5"
              data-aos="fade-up"
              data-aos-delay="10"
            >
              <img
                src="@/assets/images/who-we-are.png"
                alt="who-we-are"
              >
            </div>
          </div><!-- .col -->
          <div class="col-lg-6">
            <!-- Section Head @s -->
            <div class="nk-block-text text-center text-md-left">
              <h6
                class="title title-xs title-s1 tc-primary"
                data-aos="fade-up"
                data-aos-delay="20"
              >
                {{ $t('aboutSection.whatIsBrainworks') }}
              </h6>
              <h2
                class="title"
                data-aos="fade-up"
                data-aos-delay="30"
              >
                <span data-text="about-subtitle" />
              </h2>
              <p
                class="lead"
                data-aos="fade-up"
                data-aos-delay="40"
              >
                {{ $t('aboutSection.subtitle') }}
              </p>
              <p
                data-aos="fade-up"
                data-aos-delay="50"
              >
                {{ $t('aboutSection.paragrafOne') }}
              </p>
              <p
                data-aos="fade-up"
                data-aos-delay="60"
              >
                {{ $t('aboutSection.paragrafTwo') }}
              </p>
            </div>
          </div><!-- .col -->
        </div><!-- .row -->
      </div><!-- .block @e -->
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      aboutSubtitle: null,
    }
  },
  computed: {
    ...mapGetters({
      language: 'landing/getLanguage',
    }),
  },
  watch: {
    language(newLanguage) {
      if (newLanguage === 'English') {
        this.englishDictionary()
      } else if (newLanguage === 'Indonesia') {
        this.indonesiaDictionary()
      }
    },
  },
  async mounted() {
    await this.initDictionary()

    if (this.language === 'English') {
      this.englishDictionary()
    } else if (this.language === 'Indonesia') {
      this.indonesiaDictionary()
    }
  },
  methods: {
    initDictionary() {
      this.aboutSubtitle = document.querySelector('[data-text="about-subtitle"]')
    },

    englishDictionary() {
      this.aboutSubtitle.innerHTML = 'We are Professional <br>IT Consultant for your <br> every need.'
    },

    indonesiaDictionary() {
      this.aboutSubtitle.innerHTML = 'Kami adalah <br>para profesional<br>bagi urusan IT anda.'
    },
  },
}
</script>

<style scoped>
</style>
