<template>
  <ul
    id="change-language"
    class="toggle-class toggle-drop toggle-drop-left drop-list drop-list-sm"
  >
    <li><span @click="setLang('English')">English</span></li>
    <li><span @click="setLang('Indonesia')">Indonesia</span></li>
  </ul>
</template>

<script>
import store from '@/store/index'

export default {
  data() {
    return {
      toggleMenu: null,
    }
  },
  mounted() {
    this.toggleMenu = document.querySelector('#change-language')
  },
  methods: {
    async setLang(lang) {
      await store.dispatch('landing/CHANGE_LANGUAGE', lang, { root: true })
      setTimeout(async () => {
        this.toggleMenu.classList.remove('open-menu')
        await store.dispatch('landing/CHANGE_TOGGLE', false, { root: true })
      }, 200)
    },
  },
}
</script>

<style scoped>
</style>
