<template>
  <footer class="nk-footer bg-stellar-grad">
    <hr class="hr hr-white-5 my-0">
    <section class="section section-m bg-transparent ov-v">
      <div class="container">
        <!-- Block @s -->
        <div class="nk-block block-footer">
          <div class="row justify-content-between">
            <div class="col-lg-4 col-sm-6">
              <div class="wgs wgs-menu text-center text-lg-left">
                <span
                  class="wgs-logo brainworks-logo"
                  @click="goTop()"
                >
                  <img
                    v-if="theme === 'light'"
                    src="@/assets/images/logo/logo-bw-dark.svg"
                    alt="logo"
                  >
                  <img
                    v-else
                    src="@/assets/images/logo/logo-bw-light.svg"
                    alt="logo"
                  >
                </span>
                <div class="wgs-body d-none">
                  <h6 class="mailto">
                    <a href="mailto:admin@brainworks.id">admin@brainworks.id</a>
                  </h6>
                </div>
              </div>
            </div><!-- .col -->
            <div class="col-lg-4 col-sm-6">
              <div class="wgs wgs-menu text-center">
                <h6 class="wgs-title wgs-title-s2">
                  {{ $t('footer.followUs') }}
                </h6>
                <div class="wgs-body ttc tc-white">
                  <ul class="social">
                    <li class="icon-list">
                      <em class="social-icon fab fa-facebook-f" />
                    </li>
                    <li class="icon-list">
                      <em class="social-icon fab fa-twitter" />
                    </li>
                    <li class="icon-list">
                      <em class="social-icon fab fa-instagram" />
                    </li>
                  </ul>
                </div>
              </div>
            </div><!-- .col -->
            <div class="col-lg-4">
              <div class="wgs wgs-menu text-center text-lg-right">
                <div class="wgs-body ml-lg-n3">
                  <ul class="wgs-links">
                    <li class="d-inline-block d-lg-block">
                      <a
                        href="#about"
                        class="d-inline-block"
                        @click="scrollPageTo('#about', $event)"
                      >
                        {{ $t('navbar.about') }}
                      </a>
                    </li>
                    <li class="d-inline-block d-lg-block">
                      <a
                        href="#services"
                        class="d-inline-block"
                        @click="scrollPageTo('#services', $event)"
                      >
                        {{ $t('navbar.services') }}
                      </a>
                    </li>
                    <li class="d-inline-block d-lg-block">
                      <a
                        href="#partnership"
                        class="d-inline-block"
                        @click="scrollPageTo('#partnership', $event)"
                      >
                        {{ $t('navbar.partnership') }}
                      </a>
                    </li>
                    <li class="d-inline-block d-lg-block">
                      <a
                        href="#contact-us"
                        class="d-inline-block"
                        @click="scrollPageTo('#contact', $event)"
                      >
                        {{ $t('navbar.contact') }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div><!-- .col -->
          </div><!-- .row -->
          <div class="footer-bottom">
            <div class="row justify-content-center copyright-footer">
              <span class="d-block">Brainworks &copy; 2021.</span>
            </div>
          </div><!-- .row -->
        </div><!-- .block @e -->
      </div>
    </section>
    <div class="nk-ovm shape-footer-sm-2" />
  </footer>
</template>

<script>
import store from '@/store/index'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      theme: 'landing/getTheme',
    }),
  },
  methods: {
    async scrollPageTo(to, event) {
      await store.dispatch('landing/SCROLL_PAGE', { to, event }, { root: true })
    },
    async goTop() {
      await store.dispatch('landing/GO_TOP', true, { root: true })
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-stellar-grad {
  * {
    color: #12183a;
  }

  .social-icon {
    color: #fff;
  }
}

.shape-footer-sm-2 {
  background-image: url(../../assets/images/shape-t-2.png);
  z-index: 1;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(../../assets/images/shape-footer.png);
    z-index: -1;
    transition: opacity .5s linear;
    opacity: 0;
  }
}

.dark-mode {
  .bg-stellar-grad {
    * {
      color: #fff;
    }
  }

  .shape-footer-sm-2 {
    &::before {
      opacity: 1;
    }
  }
}

.wgs-menu {

  .wgs-body {
    .social {
      li {
        padding: 0 10px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        .social-icon {
          &:hover {
            background: #004ae2;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }

    .wgs-links {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.copyright-footer {
  font-size: 14px;
}

</style>
