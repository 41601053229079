<template>
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FFFFFF;"
      d="M492.533,393.514c-45.591,71.117-125.26,118.288-215.959,118.484
        C136.061,512.303,20.686,398.644,18.987,258.141C17.389,125.938,115.61,16.341,243.014,0.027c2.723-0.349,4.497,2.766,2.842,4.956
        c-30.719,40.661-48.931,91.286-48.931,146.177c0,134.063,108.676,242.738,242.738,242.738c17.004,0,33.592-1.744,49.607-5.075
        C491.959,388.264,494.015,391.202,492.533,393.514z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M489.276,388.819c2.684-0.558,4.74,2.373,3.261,4.682C446.851,464.773,366.946,512,276.009,512
        C134.044,512,18.972,396.917,18.972,254.963c0-56.808,18.424-109.306,49.635-151.86c-7.086,23.245-10.961,47.888-11.116,73.419
        C56.624,319.554,171.5,435.157,314.535,435.151c51.831-0.002,100.076-15.342,140.436-41.74
        C466.655,392.692,478.106,391.143,489.276,388.819z"
    />
  </svg>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
</style>
