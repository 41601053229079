<template>
  <section
    id="services"
    class="section bg-light-alt"
  >
    <div class="background-shape bs-right" />
    <div class="container">
      <div class="section-head text-center text-md-left">
        <h6
          class="title title-xs title-s1 tc-primary"
          data-aos="fade-up"
          data-aos-delay="10"
        >
          {{ $t('serviceSection.brainworksService') }}
        </h6>
        <h2
          class="title"
          data-aos="fade-up"
          data-aos-delay="20"
        >
          {{ $t('serviceSection.title') }}
        </h2>
        <p
          data-aos="fade-up"
          data-aos-delay="30"
        >
          {{ $t('serviceSection.description') }}
        </p>
      </div>
      <!-- Block @s -->
      <div class="nk-block">
        <div class="row gutter-vr-40px justify-content-center">
          <div
            class="col-md-6 col-lg-4"
          >
            <div
              class="feature feature-s8 feature-s8-alt feature-center card card-full-lgcard-md bg-white"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <div class="feature-icon feature-icon-lg feature-icon-lg-s2">
                <img
                  src="@/assets/images/icons/brainworks-services-app.png"
                  alt="Software Development"
                >
              </div>
              <div class="feature-text feature-text-s8">
                <h4 class="title title-sm title-thin title-s5">
                  <span>{{ $t('serviceSection.softwareDev.title') }}</span>
                </h4>
                <p>
                  <span data-text="software-development" />
                </p>
              </div>
            </div>
          </div><!-- .col -->
          <div
            class="col-md-6 col-lg-4"
            data-aos="fade-up"
            data-aos-delay="75"
          >
            <div
              class="feature feature-s8 feature-s8-alt feature-center card card-full-lgcard-md bg-white"
              data-aos="fade-up"
              data-aos-delay="75"
            >
              <div class="feature-icon feature-icon-lg feature-icon-lg-s2">
                <img
                  src="@/assets/images/icons/brainworks-services-bi.png"
                  alt="Business Intelligence"
                >
              </div>
              <div class="feature-text feature-text-s8">
                <h4 class="title title-sm title-thin title-s5">
                  <span>{{ $t('serviceSection.businessIntelligence.title') }}</span>
                </h4>
                <p>
                  <span data-text="business-intelligence" />
                </p>
              </div>
            </div>
          </div><!-- .col -->
          <div
            class="col-md-6 col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div
              class="feature feature-s8 feature-s8-alt feature-center card card-full-lgcard-md bg-white"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="feature-icon feature-icon-lg feature-icon-lg-s2">
                <img
                  src="@/assets/images/icons/brainworks-services-mobile.png"
                  alt="Mobile Development"
                >
              </div>
              <div class="feature-text feature-text-s8">
                <h4 class="title title-sm title-thin title-s5">
                  <span>{{ $t('serviceSection.mobileDev.title') }}</span>
                </h4>
                <p>
                  <span data-text="mobile-development" />
                </p>
              </div>
            </div>
          </div><!-- .col -->
        </div><!-- .row -->
      </div><!-- .block @e -->
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      softwareDevelopment: null,
      businessIntelligence: null,
      mobileDevelopment: null,
    }
  },
  computed: {
    ...mapGetters({
      language: 'landing/getLanguage',
    }),
  },
  watch: {
    language(newLanguage) {
      if (newLanguage === 'English') {
        this.englishDictionary()
      } else if (newLanguage === 'Indonesia') {
        this.indonesiaDictionary()
      }
    },
  },
  async mounted() {
    await this.initDictionary()

    if (this.language === 'English') {
      this.englishDictionary()
    } else if (this.language === 'Indonesia') {
      this.indonesiaDictionary()
    }
  },
  methods: {
    initDictionary() {
      this.softwareDevelopment = document.querySelector('[data-text="software-development"]')
      this.businessIntelligence = document.querySelector('[data-text="business-intelligence"]')
      this.mobileDevelopment = document.querySelector('[data-text="mobile-development"]')
    },

    englishDictionary() {
      this.softwareDevelopment.innerHTML = 'We listen and get to the heart of each consulting and design project from each client, understanding their requirement and offering a holistic solution. We advise clients on the idea but guide them thru the execution, based on a proposed project timeline. BTI is committed to quality and satisfactory outcomes for all clients.'
      this.businessIntelligence.innerHTML = '<strong>Business Intelligence (BI)</strong> comprises the Technologies and Strategies used by enterprises for the Data analysis of Business information Consulting.'
      this.mobileDevelopment.innerHTML = 'We also develop mobile apps customizing it  according to your needs. Developing these high apps, requires constant engagement where we have a dedicated team throughout that journey and facilitating modern Android and iOS applications. When each app is ready, we close the loop by publishing them in both Google Play and Apple Store.'
    },

    indonesiaDictionary() {
      this.softwareDevelopment.innerHTML = 'Kami menerima konsultasi dan desain proyek sesuai dengan keinginan klien. Kami berkonsultasi dengan klien tentang bagaimana memiliki ide yang mendalam, kemudian memberikan estimasi biaya dan jadwal proyek yang diusulkan. <strong>Brainworks</strong> berkomitmen pada kualitas dan hasil yang memuaskan bagi para klien.'
      this.businessIntelligence.innerHTML = '<strong>Business Intelligence (BI)</strong> terdiri dari teknologi dan strategi yang digunakan oleh perusahaan untuk analisis data dari konsultasi infomasi bisnis.'
      this.mobileDevelopment.innerHTML = 'Kita dapat mengembangkan aplikasi mobile dan membuat aplikasi berdasarkan kebutuhan anda, dikembangkan dengan aplikasi kualitas tinggi, menarik dan terlihat modern baik untuk android dan iOS. Saat aplikasi sudah siap, kami membantu anda mempublikasikan aplikasi anda ke Play Store dan App Store.'
    },
  },
}
</script>

<style scoped>
</style>
